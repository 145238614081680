<template>
  <span>{{computedValue}}</span>
</template>

<script>
  export default {
    props: {
      value: {type: String, default: ''}
    },
    name: "AssetItemPropertyLabel",
    computed: {
      computedValue() {
        if (this.$i18n.te(this.value)) {
          return this.$i18n.t(this.value);
        }
        return this.value
      }
    }
  }
</script>

<style scoped>

</style>
