export default class Url {
  constructor(build) {
    this.urlString = build.baseUrl + build.elements.join("/")
  }

  static get Builder() {
    class Builder {
      constructor(baseUrl) {
        if (baseUrl.endsWith("/")) {
          this.baseUrl = baseUrl
        } else {
          this.baseUrl = baseUrl + "/"
        }
      }

      addElement(element) {
        if (this.elements === undefined) {
          this.elements = []
        }
        this.elements.push(element);
        return this
      }

      build() {
        return new Url(this);
      }
    }

    return Builder;
  }
}

