<template>
  <v-tooltip :top="top" :bottom="bottom" :left="left" :right="right" open-on-hover :open-delay="openDelay"
             :open-on-click="openOnClick">
    <template v-slot:activator="{on}">
      <v-btn :disabled="disabled" tile :color="color" :text="hasText" :icon="hasIcon" v-on="on" @click.stop="click" :to="to">
        <v-badge left overlap="" :value="badgeValue !== -1337 && badgeValue !== 0">
          <span slot="badge">{{badgeValue}}</span>
					<portal-icon :portal-icon-id="portalIconId"/>
          {{buttonText}}
        </v-badge>
      </v-btn>
    </template>
    <slot><span>{{tooltip}}</span></slot>
  </v-tooltip>
</template>

<script>
  import PortalIcon from "./PortalIcon";
	export default {
		components: {PortalIcon},
		props: {
			portalIconId: {type: String, default: ''},
      color: {type: String, default: ''},
      outlined: {type: Boolean, default: false},
      rounded: {type: Boolean, default: false},
      badgeValue: {type: Number, default: -1337},
      buttonText: {type: String, default: ''},
      tooltip: {type: String, default: ''},
      openDelay: {type: Number, default: 1000},
      openOnClick: {type: Boolean, default: false},
      top: {type: Boolean, default: false},
      bottom: {type: Boolean, default: false},
      left: {type: Boolean, default: false},
      right: {type: Boolean, default: false},
      to: {type: [ String, Object ], default: ''},
      disabled: {type: Boolean, default: false}
    },
    name: "IconButton",

    methods: {
      click() {
        this.$emit('click')
      }
    },
    computed: {
      hasText() {
        return this.buttonText !== ''
      },
      hasIcon() {
        return this.portalIconId !== '' && this.buttonText === ''
      }
    }
  }
</script>

<style scoped>

</style>
