import {
  mdiAccount,
  mdiAccountSupervisorCircle,
  mdiAccountSupervisorCircleOutline,
  mdiAlert,
  mdiAlertCircle,
  mdiAlertCircleOutline,
  mdiAlertDecagram,
  mdiAlertDecagramOutline,
  mdiAlertOctagon,
  mdiAlertOctagonOutline,
  mdiAlertOutline,
  mdiBasketMinusOutline,
  mdiBasketOffOutline,
  mdiBasketOutline,
  mdiBasketPlusOutline,
  mdiBriefcaseDownloadOutline,
  mdiBriefcaseMinusOutline,
  mdiBriefcaseOffOutline,
  mdiBriefcasePlusOutline,
  mdiCalendar,
  mdiCalendarOutline,
  mdiCart,
  mdiCartMinus,
  mdiCartOff,
  mdiCartPlus,
  mdiCheckboxBlankCircle,
  mdiCheckboxBlankCircleOutline,
  mdiCheckboxMarkedCircle,
  mdiCheckboxMarkedCircleOutline,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
  mdiClipboardAlert,
  mdiClipboardAlertOutline,
  mdiClipboardMinusOutline,
  mdiClipboardOffOutline,
  mdiClipboardOutline,
  mdiClipboardPlusOutline,
  mdiClock,
  mdiClockOutline,
  mdiCloseCircleOutline,
  mdiCloudDownload,
  mdiCloudDownloadOutline,
  mdiContentCopy,
  mdiCopyright,
  mdiDelete,
  mdiDeleteForever,
  mdiDeleteForeverOutline,
  mdiDeleteOff,
  mdiDeleteOffOutline,
  mdiDeleteOutline,
  mdiDownload,
  mdiDownloadOff,
  mdiDownloadOffOutline,
  mdiDownloadOutline,
  mdiEarth,
  mdiExitToApp,
  mdiEye,
  mdiEyeOff,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiFileAlertOutline,
  mdiFolder,
  mdiFormatListBulleted,
  mdiFormatListBulletedSquare,
  mdiHeart,
  mdiHeartOutline,
  mdiHome,
  mdiHomeOutline,
  mdiInformationOutline,
  mdiLink,
  mdiLock,
  mdiLockOutline,
  mdiMagnify,
  mdiMagnifyMinusOutline,
  mdiMagnifyPlusOutline,
  mdiMenu,
  mdiMessageAlert,
  mdiMessageAlertOutline,
  mdiPaperclip,
  mdiPlayCircleOutline,
  mdiPlusCircleOutline,
  mdiSimAlert,
  mdiSimAlertOutline,
  mdiTimerOffOutline,
  mdiTimerOutline,
  mdiViewModule,
  mdiWeb
} from '@mdi/js'

export default {
  icons: {
    mdiAccount,
    mdiAccountSupervisorCircle,
    mdiAccountSupervisorCircleOutline,
    mdiAlert,
    mdiAlertCircle,
    mdiAlertCircleOutline,
    mdiAlertDecagram,
    mdiAlertDecagramOutline,
    mdiAlertOctagon,
    mdiAlertOctagonOutline,
    mdiAlertOutline,
    mdiBasketMinusOutline,
    mdiBasketOffOutline,
    mdiBasketOutline,
    mdiBasketPlusOutline,
    mdiBriefcaseDownloadOutline,
    mdiBriefcaseMinusOutline,
    mdiBriefcaseOffOutline,
    mdiBriefcasePlusOutline,
    mdiCalendar,
    mdiCalendarOutline,
    mdiCart,
    mdiCartMinus,
    mdiCartOff,
    mdiCartPlus,
    mdiCheckboxBlankCircle,
    mdiCheckboxBlankCircleOutline,
    mdiCheckboxMarkedCircle,
    mdiCheckboxMarkedCircleOutline,
    mdiChevronDown,
    mdiChevronLeft,
    mdiChevronRight,
    mdiChevronUp,
    mdiClipboardAlert,
    mdiClipboardAlertOutline,
    mdiClipboardMinusOutline,
    mdiClipboardOffOutline,
    mdiClipboardOutline,
    mdiClipboardPlusOutline,
    mdiClock,
    mdiClockOutline,
    mdiCloseCircleOutline,
    mdiCloudDownload,
    mdiCloudDownloadOutline,
    mdiContentCopy,
    mdiCopyright,
    mdiDelete,
    mdiDeleteForever,
    mdiDeleteForeverOutline,
    mdiDeleteOff,
    mdiDeleteOffOutline,
    mdiDeleteOutline,
    mdiDownload,
    mdiDownloadOff,
    mdiDownloadOffOutline,
    mdiDownloadOutline,
    mdiEarth,
    mdiExitToApp,
    mdiEye,
    mdiEyeOff,
    mdiEyeOffOutline,
    mdiEyeOutline,
    mdiFileAlertOutline,
    mdiFolder,
    mdiFormatListBulleted,
    mdiFormatListBulletedSquare,
    mdiHeart,
    mdiHeartOutline,
    mdiHome,
    mdiHomeOutline,
    mdiInformationOutline,
    mdiLink,
    mdiLock,
    mdiLockOutline,
    mdiMagnify,
    mdiMagnifyMinusOutline,
    mdiMagnifyPlusOutline,
    mdiMenu,
    mdiMessageAlert,
    mdiMessageAlertOutline,
    mdiPaperclip,
    mdiPlayCircleOutline,
    mdiPlusCircleOutline,
    mdiSimAlert,
    mdiSimAlertOutline,
    mdiTimerOffOutline,
    mdiTimerOutline,
    mdiViewModule,
    mdiWeb
  }
}