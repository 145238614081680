import BaseEntity from "./BaseEntity"

export default class Asset extends BaseEntity {
  isLandscape() {
    return this.getPropertyValue('previewHeight') < this.getPropertyValue('previewWidth')
  }
  isVideo(){
    //THE second property value is currently a hack for celum only
    return this.getPropertyValue('ASSET_TYPE') === 'VIDEO' | this.getPropertyValue('ASSET_TYPE') === '3'
  }
  name() {
    return this.getPropertyValue('ASSET_NAME')
  }
  fields(){
    return this.entity.entityFields
  }
}
