<template>
  <v-dialog v-if="visible" :max-width="termsOfUse.maxWidth" :width="termsOfUse.width" v-model="visible" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{termsOfUse.title}}</span>
      </v-card-title>
      <v-card-text>
        <span v-html="termsOfUse.htmlBody"></span>
        <span v-if="termsOfUse.showVersion" class="text--black">Version {{termsOfUse.version}}</span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" @click="acceptTermsOfUse">{{termsOfUse.buttonText}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import remoteApi from "../../services/remoteApi"
  import localApi from "../../services/localApi"

  export default {
    data() {
      return {
        termsOfUse: null,
        visible: false
      }
    },
    beforeCreate() {
      remoteApi.loadTermsOfUse(this.$i18n.locale).then(data => {
        this.termsOfUse = data
        this.visible = true
      })
    },
    methods: {
      acceptTermsOfUse() {
        this.visible = false
        localApi.acceptTermsOfUse(this.termsOfUse)
        this.$router.push({name: 'Home', params: {portal: this.$store.state.main.portal}}).catch(() => {})
      }
    }
  }
</script>
<style lang="css" scoped>
</style>
