<template>
  <div>
    <video ref="videoPlayer" class="video-js vjs-big-play-centered"/>
  </div>
</template>

<script>
  import videojs from 'video.js';
  import 'video.js/dist/video-js.css'
  import _ from 'lodash'

  export default {
    name: "VideoPlayer",
    props: {
      assetItem: {
        type: Object, default() {
          return {}
        }
      },
      previewUrl: {type: String, default: ''},
      width:{type:Number,default:600},
      height:{type:Number,default:338}
    },
    data() {
      return {
        player: null,
        options: {
          resizeManager: true,
          responsive: true,
          controls: true,
          autoplay: true,
          sources: [],
          width: 600,
          height: 338,
          poster: ''
        }
      }
    },
    created() {
      window.addEventListener("resize", this.resizeEventHandler);
      this.options.width = this.width //this.$vuetify.breakpoint.width - 30
      this.options.height = this.height //this.$vuetify.breakpoint.height - 160
      this.options.sources.push({src: this.previewUrl, type: 'video/mp4'})
      this.options.poster = this.assetItem.getPropertyValue('defaultPreview')
    },
    mounted() {
      this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {

      })
    },
    beforeDestroy() {
      if (this.player) {
        this.player.dispose()
      }
      window.removeEventListener("resize", this.resizeEventHandler);
    },

    methods: {
      resizeEventHandler: _.debounce(() => {
        this.player.height(this.$vuetify.breakpoint.height - 160)
        this.player.width(this.$vuetify.breakpoint.width - 30)
      }, 100)
    }
  }
</script>

<style>

</style>
