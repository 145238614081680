<template>
  <v-sheet :color="borderAlertColor" tile class="ma-1 pa-1">
    <v-card tile>
      <component v-for="(element, index) in filteredComponents" :is="getComponent(element)"
                 :key="index" v-bind="getCurrentProperties(element)" v-on="$listeners"></component>
    </v-card>
  </v-sheet>
</template>
<script>
import AssetTilePreview from "./AssetTilePreview"
import AssetTileActionButtonGroup from "./AssetTileActionButtonGroup"
import AssetTileText from "./AssetTileText"
import AssetTileIconGroup from "./AssetTileIconGroup"

const elementMap = {
  "ACTION_BUTTON_BAR": "AssetTileActionButtonGroup",
  "ICON_GROUP": "AssetTileIconGroup",
  "IMAGE_PREVIEW": "AssetTilePreview",
  "TEXT": "AssetTileText"
}
export default {
  components: {AssetTileIconGroup, AssetTileText, AssetTileActionButtonGroup, AssetTilePreview},
  props: ['assetItem'],
  methods: {
    getComponent(element) {
      return elementMap[element.elementType];
    },
    getCurrentProperties(element) {
      return {properties: element.properties, assetItem: this.assetItem}
    }
  },
  computed: {
    borderAlertColor() {
      return this.assetItem.entity.displayElements.TILE
          .filter(e => e.elementType === "ASSET_ITEM_TILE_BORDER")
          .flatMap(b => b.properties)
          .filter(b => b.color !== undefined)
          .map(b => b.color)[0] || ""
    },
    filteredComponents() {
      return this.assetItem.entity.displayElements.TILE
          .filter(e => e.elementType !== "ASSET_ITEM_TILE_BORDER")
          .filter(e => e.elementType !== undefined)
    }
  }
}
</script>
