<template>
  <span :class="cssClass">{{value}}</span>
</template>

<script>
  import moment from 'moment';
  import {i18n} from "../i18n/i18n.js"

  function formatBytes(bytes) {
    const thousand = 1024
    const decimal = 3
    const kiloBytes = thousand
    const megaBytes = thousand * thousand
    const gigaBytes = thousand * thousand * thousand

    if (bytes < kiloBytes) return bytes + " Bytes";
    if (bytes < megaBytes) return (bytes / kiloBytes).toFixed(decimal) + " KB";
    if (bytes < gigaBytes) return (bytes / megaBytes).toFixed(decimal) + " MB";
    return (bytes / gigaBytes).toFixed(decimal) + " GB";
  }

  function translate(value) {
    if(value === '') return value
    if(i18n.te(value)){
      return i18n.t(value)
    }
    return value
  }

  export default {
    props: {
      assetItem: {type: Object},
      name: {type: String, default: ''},
      cssClass: {type: String, default: ''}
    },
    name: "AssetItemProperty",
    computed: {
      value() {
        let property = this.assetItem.getProperty(this.name)
        if (property.type === 'DATE') {
          if(property.format !== ''){
            moment.locale(i18n.locale)
            return moment(parseInt(property.value)).format(property.format)
          }
          return moment(parseInt(property.value))
        }
        if (property.type === "BYTE_SIZE") {
          return formatBytes(parseInt(property.value))
        }
        if(property.displayValue === true){
          return (translate(property.prefix) +' '+ property.value +' '+ translate(property.suffix)).trim()
        }
        return property.value
      }
    }
  }
</script>

<style scoped>

</style>
