<template>
  <span>{{value}}</span>
</template>
<script>
  export default {
    props: {
      field: {
        type: Object, default() {
          return {}
        }
      },
      suffix: {type: String, default: ''}
    },
    computed: {
      value() {
        return this.field.name+this.suffix
      }
    }
  }
</script>
<style lang="css" scoped>
</style>
