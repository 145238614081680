<template>
  <div>
  <v-container grid-list-md="true" fluid :style="padding">

      <v-row v-if="config.portalUIConfiguration.viewType === 'tile'">
        <v-col v-for="assetItem in assets" :key="'tile'+assetItem.id" cols="6" md="3" sm="4" :lg="lg" :xl="xl" class="pa-2">
          <asset-tile :asset-item="assetItem"
                      @addBasket="addAsset"
                      @removeBasket="removeAsset"
                      @download="download"
                      @showDetail="showDetail"
                      @showPreview="showPreview"
                      @showImagerUrlDialog="showImagerDialog"/>
        </v-col>
      </v-row>
      <template v-if="config.portalUIConfiguration.viewType === 'row'">
        <v-row>
          <v-col cols="1">{{$t('listViewThumb')}}</v-col>
          <v-col cols="1"></v-col>
          <v-col cols="5">{{$t('listViewAssetName')}}</v-col>
          <v-col cols="2">{{$t('DATE_MODIFIED')}}</v-col>
          <v-col cols="2" class="d-none d-sm-flex">{{$t('ORIGINAL_FILE_SIZE')}}</v-col>
          <v-spacer/>
          <v-col cols="1" align="center"/>
        </v-row>
        <asset-list-item :asset-item="assetItem" v-for="assetItem in assets" :key="'row'+assetItem.id"
                         @addBasket="addAsset"
                         @removeBasket="removeAsset"
                         @download="download"
                         @showDetail="showDetail"
                         @showPreview="showPreview"
        />
      </template>
      <infinite-loading @infinite="infiniteHandler" ref="infiniteLoader">
        <template slot="no-results">{{$t('noAssetsFound')}}</template>
        <template slot="no-more">{{$t('noMoreAssets')}}</template>
        <template slot="spinner">
          <div class="pt-3">
            <v-progress-circular :size="50" color="primary" indeterminate/>
          </div>
        </template>
      </infinite-loading>
      <router-view/>
    </v-container>
    <notifications></notifications>
  </div>
</template>
<script>
	import {mapActions, mapState} from 'vuex'
	import InfiniteLoading from 'vue-infinite-loading'
	import AssetTile from "../../components/AssetTile"
	import AssetListItem from "../../components/AssetListItem"
	import remoteApi from "../../services/remoteApi"
	import {debounce} from "lodash"
  import Notifications from "../../components/Notifications"

	export default {
		components: {
      Notifications,
			AssetTile,
			AssetListItem,
			InfiniteLoading,
		},
		data() {
			return {
				iframeDownloadSrc: '',
				downloadName: '',
				infiniteLoadingInitialized: false
			}
		},
		methods: {
			...mapActions({
				'loadAssets': 'assets/loadAssets',
				'addAsset': 'basket/add',
				'removeAsset': 'basket/remove'
			}),
			showPreview(asset) {
				// this.$store.commit("assets/SET_SELECTED_ASSET", asset)
				this.$router.push({name: 'AssetPreview', params: {'assetId': asset.id}})
			},
			showDetail(asset) {
				this.$router.push({name: 'AssetDetail', params: {'assetId': asset.id}})
			},
      showImagerDialog(asset){
        this.$router.push({name: 'AssetUrls', params: {'assetId': asset.id}})
      },
			download(asset) {
				remoteApi.executeDirectDownload(asset)
			},

			infiniteHandler($state) {
				this.loadAssets().then(result => {
					if (result.hasItems) {
						$state.loaded();
						if (result.hasMoreItems) {
							$state.complete();
						}
					} else {
						$state.complete();
					}
				})
			}
		},
		computed: {
			...mapState({
				queryValues: state => state.search.queryValues,
				assets: state => state.assets.assets,
				page: state => state.assets.page,
				detailSideBarVisible: state => state.config.local.ui.showRightSideBar,
				config: state => state.config.server
			}),
			padding() {
				return {
					'padding-top': (this.config.portalUIConfiguration.searchInformationHeaderHeight+18)+'px'
				}
			},
			lg() {
				if (this.detailSideBarVisible) {
					return 4
				}
				return 3
			},
			xl() {
				if (this.detailSideBarVisible) {
					return 2
				}
				if (this.$vuetify.breakpoint.width < 2600) {
					return 2
				}
				return 1
			}
		},
		watch: {
			queryValues: {
				deep: true,
				handler: debounce(function () {
					if (this.$refs.infiniteLoader) {
						this.$store.commit('assets/RESET_PAGE')
						this.$store.dispatch("search/updateQueryValues")
						this.$refs.infiniteLoader.stateChanger.reset();
					}
				}, 1000)
			}
		}
	}
</script>
<style lang="css" scoped>
</style>
