<template>
  <div style="width: 100%">
    <v-divider v-if="topDivider" class="pb-0 pt-0"></v-divider>
    <v-row no-gutters class="pb-0 pt-0">
      <slot></slot>
    </v-row>
    <v-divider v-if="bottomDivider" class="pb-0 pt-0"></v-divider>
  </div>
</template>

<script>
  export default {
    name: "AssetRow",
    props:{
      topDivider:{type:Boolean, default:true},
      bottomDivider:{type:Boolean, default:true},
    }
  }
</script>

<style scoped>

</style>
