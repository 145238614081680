import Vue from "vue"
import VueI18n from "vue-i18n"
import messages from "./translations"

Vue.use(VueI18n)
let clientLocale = navigator.language.substring(0, 2)

export const i18n = new VueI18n({
  locale: clientLocale,
  fallbackLocale: 'en',
  messages
});
