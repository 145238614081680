const messages = {
  en: {
    /* Login */
    'loginHeadLine': 'Please login',
    'labelUsername': 'Enter your username',
    'labelPassword': 'Enter your password',
    'btnLogin': 'Login',
    'loginFailed': 'User name or password mismatch',
    'userNameRequired': 'The username is required',
    'passwordRequired': 'The password is required',

    /* Main */
    'quickSearch': 'Search',
    'btnClose': 'Close',
    'btnNext': 'Next',
    'btnPrevious': 'Previous',
    'btnSearch': 'Search',
    'btnAcceptTermsOfUse': 'Accept Terms of Use',
    'noMoreAssets': 'There are no more assets',
    'noAssetsFound': 'No assets have been found',
    'filterHeader': 'Filter',
    'searchInformationHeader.totalAssets': 'Total Assets',
    'searchInformationHeader.clearAll': 'Clear all',
    'portalHeaderTitle': '',

    /* Asset Values */
    'ASSET_ID': 'Asset id',
    'ASSET_NAME': 'Asset name',
    'DATE_CREATED': 'Creation date',
    'CREATED_BY': 'Created by',
    'DATE_MODIFIED': 'Last modified',
    'MODIFIED_BY': 'Last modified by',
    'DATE_BINARY_VERSION': 'Date binary version',
    'BINARY_VERSION': 'Binary version',
    'BINARY_VERSION_BY': 'Binary version by',
    'FILE_EXTENSION': 'File extension',
    'ASSET_TYPE': 'Asset type',
    'ORIGINAL_FILE_NAME': 'Original file name',
    'ORIGINAL_FILE_SIZE': 'File size',
    'WIDTH': 'Width',
    'HEIGHT': 'Height',
    'COLOR_SPACE': 'Colorspace',
    'ACTIVE_STATUS': 'Active status',
    'ASSET_STATUS': 'Asset status',

    'score': 'Score',
    'previewPageCount': 'Page count',
    'assetValidationStatus': 'Asset validation status',
    'path': 'Path',
    'locked': 'Locked',

    /* Basket */
    'basketTitle': 'Your basket contains {numItems} asset(s)',
    'basketThumb': 'Thumbnail',
    'basketAssetName': 'Asset name',
    'basketDownloadFormat': 'Download format',
    'basketActions': 'Action',
    'basketDownload': 'Download',
    'basketMailDownload': 'Request Download',
    'basketSendBasket': 'Send basket',
    'basketOrderBasket': 'Order basket',
    'addSearchResultToBasket': 'Add Search Result to Basket',
    'basketIsFull': 'The basket is full',
    'createPortalDownload': 'Create Portal Download',

    /* Basket E-Mail Download Dialog */
    'mailDownloadDialogEnterEMail': 'Please enter your email address.',
    'mailDownloadDialogVerificationEmailSending': 'A download link will be sent to the provided address.',
    'mailDownloadDialogYourEMailAddress': 'Your email address',
    'mailDownloadDialogButtonCancel': 'Cancel',
    'mailDownloadDialogButtonSubmit': 'Submit',
    'mailDownloadDialogProcessingRequest': 'Processing your request',
    'mailDownloadDialogSuccess': 'Success',
    'mailDownloadDialogCheckInbox': 'Please check your Inbox',
    'mailDownloadDialogClose': 'Close',
    'mailDownloadDialogError': 'Error',
    'mailDownloadDialogErrorOccurred': 'An error occurred, please try again.',
    'mailDownloadDialogRequired': 'Required',
    'mailDownloadDialogInvalidEMail': 'Invalid email address!',

    /* Create Portal Download Dialog */
    'createPortalDownloadTitle':'Create Portal Download',
    'createPortalDownloadSubtitle':'Create a Portal Download Link',
    'portalDownloadName': 'Name',
    'portalDownloadInformation': 'Information',
    'portalDownloadFormat': 'Download Formats',
    'validFrom': 'Valid from',
    'validTo': 'Valid to',
    'dateInvalid':'Invalid date',
    'noDownloadFormatSelected':'Please select a least one download format',
    'pdCancel':'Cancel',
    'pdOk':'Ok',
    'createPortalDownloadButton':'Create',
    'cancelCreatePortalDownloadButton':'Cancel',
    "portalDownloadCreatedTitle": "Portal Download created",
    "createPortalDownloadSuccess": "Portal Download successfully created",
    "createPortalDownloadError": "Server error",
    "copyPortalDownloadUrl": "Copy",

    /* Detail View */
    'detailTabProperties': 'Properties',
    'detailTabMetadata': 'Metadata',
    'detailTabName': 'Name',
    'detailTabValue': 'Value',
    'Pixel': 'Pixel',

    /* List View */
    'listViewThumb': 'Thumbnail',
    'listViewAssetName': 'Asset name',
    'listViewActions': 'Action',

    /* Search */
    'searchRecentSearches': 'Recent searches',
    'searchDeleteSearch': 'Delete search',
    'searchExecuteSearch': 'Execute search',
    'searchClearSearchHistory': 'Clear search history',
    'searchInputNotLongEnough': 'The search must be at least {numChars} characters long',
    'searchInputTooLong': 'The search exceeds the maximum of {numChars} characters',
    'searchInputNotValid': 'The search contains invalid characters',

    /* Tooltips */
    'tooltipShowDetailView': 'Show details',
    'tooltipDownloadAsset': 'Download asset',
    'tooltipAddToBasket': 'Add asset to basket',
    'tooltipRemoveFromBasket': 'Remove asset from basket',
    'tooltipClearBasket': 'Clear whole basket',
    'tooltipQuickSearch': 'Quick search',
    'tooltipShowListView': 'Show list view',
    'tooltipShowThumbnailView': 'Show thumbnail view',
    'tooltipShowBasket': 'Show basket',
    'tooltipExitApplication': 'Exit application',
    'tooltipToggleLeftSidebar': 'Toggle left sidebar',
    'tooltipCloseDialog': 'Close dialog',
    'toolTipImagerUrlCopyDialog': 'Copy Image URL',

    /* Imager Integration */
    'imagerDialogTitle': 'Available Image Links',
    'imagerCopyImageLink': 'Copy Image Link',
    'imagerPreviewImageInTab': 'Preview Image in Tab',
    'imagerCloseDialog': 'Close',
    'imagerOriginalTitle': 'Original',
    'imagerServiceError':'Error: Imager service is not responding',

    /* Notifications */
    'closeNotification':'Close'
  },
  de: {
    /* login */
    'loginHeadLine': 'Bitte melden Sie sich an',
    'labelUsername': 'Geben Sie Ihren Benutzernamen ein',
    'labelPassword': 'Geben Sie Ihr Passwort ein',
    'btnLogin': 'Anmelden',
    'loginFailed': 'Benutzername oder Passwort falsch',
    'userNameRequired': 'Der Benutzername ist erforderlich',
    'passwordRequired': 'Das Passwort ist erforderlich',

    /* Main */
    'quickSearch': 'Suche',
    'btnClose': 'Schließen',
    'btnNext': 'Weiter',
    'btnPrevious': 'Zurück',
    'btnSearch': 'Suchen',
    'btnAcceptTermsOfUse': 'Nutzungsbedingungen akzeptieren',
    'noMoreAssets': 'Es sind keine weiteren Dateien vorhanden',
    'noAssetsFound': 'Es wurden keine Dateien gefunden',
    'filterHeader': 'Filter',
    'searchInformationHeader.totalAssets': 'Anzahl Assets',
    'searchInformationHeader.clearAll': 'Alles zurücksetzen',
    'portalHeaderTitle': '',

    /* Asset Values */
    'ASSET_ID': 'Asset ID',
    'ASSET_NAME': 'Dateiname',
    'DATE_CREATED': 'Erstellungsdatum',
    'CREATED_BY': 'Erstellt von',
    'DATE_MODIFIED': 'Letzte Änderung',
    'MODIFIED_BY': 'Letzte Änderung von',
    'DATE_BINARY_VERSION': 'Datum binäre Version',
    'BINARY_VERSION': 'Binäre Version',
    'BINARY_VERSION_BY': 'Binäre Version von',
    'FILE_EXTENSION': 'Dateiendung',
    'ASSET_TYPE': 'Dateityp',
    'ORIGINAL_FILE_NAME': 'Original Dateiname',
    'ORIGINAL_FILE_SIZE': 'Dateigröße',
    'WIDTH': 'Breite',
    'HEIGHT': 'Höhe',
    'COLOR_SPACE': 'Farbraum',
    'ACTIVE_STATUS': 'Aktiver Status',
    'ASSET_STATUS': 'Dateistatus',

    'score': 'Wertung',
    'previewPageCount': 'Seitenanzahl',
    'assetValidationStatus': 'Datei-Validierungsstatus',
    'path': 'Pfad',
    'locked': 'Gesperrt',

    /* Basket */
    'basketTitle': 'Ihr Warenkorb enthält {numItems} Datei(en)',
    'basketThumb': 'Vorschau',
    'basketAssetName': 'Dateiname',
    'basketDownloadFormat': 'Downloadformat',
    'basketActions': 'Aktion',
    'basketDownload': 'Download',
    'basketMailDownload': 'Download bestellen',
    'basketSendBasket': 'Warenkorb senden',
    'basketOrderBasket': 'Warenkorb bestellen',
    'addSearchResultToBasket': 'Alle Bilder in den Warenkorb übernehmen',
    'basketIsFull': 'Der Warenkorb ist voll',
    'createPortalDownload': 'Portal Download erstellen',

    /* Basket E-Mail Download Dialog */
    'mailDownloadDialogEnterEMail': 'Bitte geben Sie Ihre E-Mail-Adresse an.',
    'mailDownloadDialogVerificationEmailSending': 'Ein Download Link wird an die angegebene E-Mail-Adresse geschickt.',
    'mailDownloadDialogYourEMailAddress': 'Ihre E-Mail-Adresse',
    'mailDownloadDialogButtonCancel': 'Abbrechen',
    'mailDownloadDialogButtonSubmit': 'Absenden',
    'mailDownloadDialogProcessingRequest': 'Ihre Anfrage wird bearbeitet.',
    'mailDownloadDialogSuccess': 'Ihre Anfrage wurde erfolgreich bearbeitet.',
    'mailDownloadDialogCheckInbox': 'Bitte prüfen Sie die Inbox Ihres E-Mail-Postfachs.',
    'mailDownloadDialogClose': 'Schließen',
    'mailDownloadDialogError': 'Fehler',
    'mailDownloadDialogErrorOccurred': 'Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut.',
    'mailDownloadDialogRequired': 'Pflichtangabe',
    'mailDownloadDialogInvalidEMail': 'Ungültige E-Mail-Adresse!',

    /* Create Portal Download Dialog */
    'createPortalDownloadTitle':'Portal Download erstellen',
    'createPortalDownloadSubtitle':'Portal Download Link erstellen',
    'portalDownloadName': 'Name',
    'portalDownloadInformation': 'Information',
    'portalDownloadFormat': 'Download Formate',
    'validFrom': 'Gültig ab',
    'validTo': 'Gültig bis',
    'dateInvalid':'Ungültiges Datum',
    'noDownloadFormatSelected':'Bitte mindestens 1 Downloadformat auswählen',
    'pdCancel':'Abbrechen',
    'pdOk':'Ok',
    'createPortalDownloadButton':'Erstellen',
    'cancelCreatePortalDownloadButton':'Abbrechen',
    "portalDownloadCreatedTitle": "Portal Download wurde erstellt",
    "createPortalDownloadSuccess": 'Portal Download wurde erfolgreich erstellt',
    "createPortalDownloadError": 'Server Fehler',
    "copyPortalDownloadUrl": 'Kopieren',

    /* Detail View */
    'detailTabProperties': 'Eigenschaften',
    'detailTabMetadata': 'Metadaten',
    'detailTabName': 'Name',
    'detailTabValue': 'Wert',
    'Pixel': 'Bildpunkte',

    /* List View */
    'listViewThumb': 'Vorschau',
    'listViewAssetName': 'Dateiname',
    'listViewActions': 'Aktion',

    /* Search */
    'searchRecentSearches': 'Vorherige Suchen',
    'searchDeleteSearch': 'Suche löschen',
    'searchExecuteSearch': 'Suche ausführen',
    'searchClearSearchHistory': 'Suchverlauf löschen',
    'searchInputNotLongEnough': 'Die Suche muss mindestens {numChars} Zeichen enhalten',
    'searchInputTooLong': 'Die Suche darf maximal {numChars} enhalten',
    'searchInputNotValid': 'Die Suche enthält nicht erlaubte Zeichen',

    /* Tooltips */
    'tooltipShowDetailView': 'Details anzeigen',
    'tooltipDownloadAsset': 'Datei herunterladen',
    'tooltipAddToBasket': 'Datei zum Warenkorb hinzufügen',
    'tooltipRemoveFromBasket': 'Datei aus dem Warenkorb entfernen',
    'tooltipClearBasket': 'Kompletten Warenkorb leeren',
    'tooltipQuickSearch': 'Schnellsuche',
    'tooltipShowListView': 'Zeige Listenansicht',
    'tooltipShowThumbnailView': 'Zeige Thumbnailansicht',
    'tooltipShowBasket': 'Zeige Warenkorb',
    'tooltipExitApplication': 'Anwendung verlassen',
    'tooltipToggleLeftSidebar': 'Linkes Seitenmenü umschalten',
    'tooltipCloseDialog': 'Fenster schließen',
    'toolTipImagerUrlCopyDialog': 'Bild URL kopieren',

    /* Imager Integration */
    'imagerDialogTitle': 'Verfügbare Image Links',
    'imagerCopyImageLink': 'Image Link kopieren',
    'imagerPreviewImageInTab': 'Bildvorschau im Tab',
    'imagerCloseDialog': 'Schliessen',
    'imagerOriginalTitle': 'Original',
    'imagerServiceError':'Fehler: Imager Dienst anwortet nicht',

    /* Notifications */
    'closeNotification':'Schliessen'
  }
}

export default messages