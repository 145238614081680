import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/Login.vue'
import {store} from './store/store'
import Maintenance from "./views/Maintenance.vue"
import TermsOfUseModal from "./views/children/TermsOfUseModal"
import ContentArea from "./views/children/ContentArea"
import AssetDetailModal from "./views/children/AssetDetailModal"
import BasketModal from "./views/children/BasketModal"
import AssetPreviewModal from "./views/children/AssetPreviewModal"
import QuickSearchDialog from "./views/children/QuickSearchDialog"
import ImagerUrlDialog from "./views/children/ImagerUrlDialog"

Vue.use(Router)

const isNotAuthenticated = (to, from, next) => {
  const portal = to.params.portal
  if (store.getters['main/authenticated'] === false) {
    next()
    return
  }
  next({name: 'Home', params: {portal: portal}})
}

const isAuthenticated = (to, from, next) => {
  const portal = store.state.main.portal
  let authenticated = store.getters['main/authenticated']
  if (authenticated !== false) {
    store.dispatch("config/loadExtendedConfig").then(() => {

        let showTermsOfUse = store.getters['config/showTermsOfUse']
        if (to.name === 'Portal') {
          next({name: 'Home', params: {portal: portal}})
        }
        if (to.name === 'Home' && showTermsOfUse) {
          next({name: 'TermsOfUse', params: {portal: portal}})
        } else {
          next()
        }
      }
    )
  } else {
    next({name: 'Login', params: {portal: portal}})
  }
}

export default {
  mode: 'history',
  routes: [
    {
      path: "/",
      name: "Root",
      redirect: to => {
        return to.path + store.state.config.base.portal + "/home"
      }
    },
    {
      path: '/:portal',
      name: 'Portal',
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "portal" */ './views/Portal.vue'),
      beforeEnter: isAuthenticated,
      children: [
        {
          path: 'home',
          name: 'Home',
          component: ContentArea,
          children: [
            {
              path: 'quicksearch',
              name: "QuickSearch",
              component: QuickSearchDialog
            },
            {
              path: 'assetPreview/:assetId',
              name: "AssetPreview",
              component: AssetPreviewModal
            },
            {
              path: 'asset/:assetId',
              name: "AssetDetail",
              component: AssetDetailModal
            },
            {
              path: 'basket',
              name: "Basket",
              component: BasketModal
            },
            {
              path: 'termsofuse',
              name: 'TermsOfUse',
              component: TermsOfUseModal
            },
            {
              path: 'assetUrls/:assetId',
              name: 'AssetUrls',
              component: ImagerUrlDialog
            }
          ]
        },
      ]
    },
    {
      path: '/:portal/maintenance',
      name: 'Maintenance',
      component: Maintenance
    },
    {
      path: '/:portal/login',
      name: 'Login',
      component: Login,
      beforeEnter: isNotAuthenticated
    },
  ]
}
