import Vue from 'vue'
import Vuex from 'vuex'
import {config} from './config-store.js'
import {assets} from './assets-store.js'
import {basket} from './basket-store.js'
import {container} from './container-store.js'
import {main} from './main-store.js'
import {search} from './search-store.js'

//Different import because we have a different module type/style
import * as notification from './notifications-store.js'


Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {main, assets, container, basket, config, notification, search}
});
