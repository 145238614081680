import Vue from 'vue'
import {i18n} from './i18n/i18n.js'
import App from './App'
import {store} from './store/store'
// import '@mdi/font/css/materialdesignicons.css'
import Vuetify from 'vuetify/lib'
import _ from 'lodash'
import vue_shortkey from "vue-shortkey"
import remoteApi from "./services/remoteApi"
import localApi from "./services/localApi"
import Router from 'vue-router'
import router from './router'
import titleMixin from './mixins/titleMixin'

Vue.mixin(titleMixin)
//Make lodash available on this -> this.$_
Object.defineProperty(Vue.prototype, '$_', {value: _});
Vue.use(Vuetify)
Vue.use(vue_shortkey)
Vue.config.productionTip = false

initClient().then( () => {
  new Vue({
    vuetify: new Vuetify({icons: {iconfont: 'mdiSvg'}}),
    router: new Router(router),
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')

})

async function initClient(){
  let response = await remoteApi.getBackend()
  if(response.data.clientLocale){
    i18n.locale = (navigator && navigator.language) || "en";
  }
  localApi.initClient(response)
}
