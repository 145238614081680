import SearchQueryValue from "../model/SearchQueryValue"

let nextId = 1;

let groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const search = {
  namespaced: true,

  state: {
    recentSearches: [],

    queryValues: {
      //Array of strings
      terms: [],
      //Array of assetContainerItems
      containers: [],
      //Array of assetContainerItems
      keywords: [],
    },
    searchQuery: {
      //SearchQuery object
      currentSearchQueryValues: []
    }
  },

  mutations: {
    SET_TERM_SEARCH_VALUES(state, searchValues) {
      state.queryValues.terms = [...searchValues]
    },

    SET_CONTAINER_SEARCH_VALUE(state, searchValue) {
      state.queryValues.containers = [searchValue]
    },

    ADD_TERM_SEARCH_VALUE(state, searchValue) {
      state.queryValues.terms.push(searchValue)
    },

    ADD_CONTAINER_SEARCH_VALUE(state, searchValue) {
      state.queryValues.containers.push(searchValue)
    },

    SET_KEYWORD_SEARCH_VALUE(state, keywords) {
      state.queryValues.keywords = keywords
    },

    REMOVE_SEARCH_VALUE(state, tag) {
      if (tag.type === "keyword") {
        state.queryValues.keywords.splice(state.queryValues.keywords.indexOf(tag.value), 1)
      }
      if (tag.type === "term") {
        state.queryValues.terms.splice(state.queryValues.terms.indexOf(tag.value), 1)
      }
      if (tag.type === "containers") {
        state.queryValues.container.splice(state.queryValues.container.indexOf(tag.value), 1)
      }
    },
    REMOVE_TAG(state, keyword) {
      state.queryValues.keywords.splice(state.queryValues.keywords.indexOf(keyword), 1)
      // this.chips = [...this.chips]
    },
    REMOVE_KEYWORD(state, keyword) {
      state.queryValues.keywords.splice(state.queryValues.keywords.indexOf(keyword), 1)
      // this.chips = [...this.chips]
    },
    RESET_TERM_SEARCH_VALUES(state) {
      state.queryValues.terms = []
    },

    RESET_CONTAINER_SEARCH_VALUES(state) {
      state.queryValues.containers = []
    },

    RESET_KEYWORD_SEARCH_VALUES(state) {
      state.queryValues.keywords = []
    },

    RESET_ALL_SEARCH_VALUES(state){
      state.queryValues.terms = []
      state.queryValues.containers = []
      state.queryValues.keywords = []
      state.searchQuery.currentSearchQueryValues = []
    },

    SET_SEARCH_SEARCH_QUERY_VALUES(state, searchQueryValues) {
      state.searchQuery.currentSearchQueryValues = [...searchQueryValues]
    },

    ADD_SEARCH(state, searchValues) {
      if (state.recentSearches.length === 10) {
        state.recentSearches.shift()
      }
      state.queryValues.terms = [...searchValues]
      state.recentSearches.push({value: searchValues, id: nextId++})
    },

    DELETE_SEARCH(state, searchToRemove) {
      state.recentSearches = state.recentSearches.filter(searches => searches.id !== searchToRemove.id)
    },

    RESET_RECENT_SEARCHES(state) {
      state.recentSearches = []
    }
  },

  actions: {
    add({commit}, search) {
      commit('ADD_SEARCH', search)
    },

    remove({commit}, searchToRemove) {
      commit('DELETE_SEARCH', searchToRemove)
    },

    updateQueryValues({commit, getters}) {
      let searchQueryValues = getters['getSearchQueryValues']
      commit("SET_SEARCH_SEARCH_QUERY_VALUES", searchQueryValues)
    }
  },

  getters: {
    recentSearches: state => {
      return state.recentSearches
    },

    getSearchQueryValueNames: state => {
      let containerNames = state.queryValues.containers
        .map(container => container.id)
        .map(containerId => {
          return {type: 'CONTAINER', value: containerId}
        })
      let keywordNames = state.queryValues.keywords
        .map(container => container.id)
        .map(containerId => {
          return {type: 'KEYWORD', value: containerId}
        })
      let terms = state.queryValues.terms
        .map(value => {
          return {type: 'TERM', value: value}
        })
      return [...containerNames, ...keywordNames, ...terms]
    },
    getSearchQueryValues: state => {
      let containerSearchValues = state.queryValues.containers
        .map(container => container.id)
        .map(containerId => new SearchQueryValue("CONTAINER", [containerId]))

      let termSearchValues = [new SearchQueryValue("TERM", state.queryValues.terms)]

      let groupedByParent = groupBy(state.queryValues.keywords, 'parent')
      let keywordSearchValues = []
      for (const key of Object.keys(groupedByParent)) {
        let values = groupedByParent[key]
        let ids = values.map(v => v.id)
        keywordSearchValues.push(new SearchQueryValue("KEYWORD", ids))
      }

      return [...containerSearchValues, ...keywordSearchValues, ...termSearchValues]
    }
  }
}
