<template>
  <v-card-text class="pa-1">
    &nbsp;<portal-bullet v-for="bullet in properties.icons"
                         :mdi-icon-name="bullet.properties['icon']"
                         :color="bullet.properties['color']"
                         :tooltip="tooltip(bullet)"
                         :key="bullet.identifier"
  />
  </v-card-text>
</template>
<script>
import PortalBullet from "./PortalBullet"

export default {
  name: 'asset-tile-icon-group',
  components: {PortalBullet},
  props: {
    assetItem: {},
    properties: {},
  },
  methods:{
    tooltip(bullet){
      if(bullet.properties['translateTooltip']){
        return this.$t(bullet.properties['tooltip'])
      }
      return bullet.properties['tooltip']
    }
  }
}
</script>
