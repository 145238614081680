<template>
    <v-dialog v-if="visible" v-model="visible" :width="width" :height="height" :fullscreen="fullscreen" overlay-opacity="0.7"
              :scrollable="false">
      <video-player width="600" height="338" v-if="selectedAsset.isVideo()" :asset-item="selectedAsset" :preview-url="videoUrl"/>
      <asset-item-preview v-else :asset-item="selectedAsset" type="PREVIEW_URL" :max-width="maxWidth" :width=
          "width" :height="height"
                          :max-height="maxHeight" :contain="true" @click="close" @imageLoaded="imageLoaded"/>
    </v-dialog>
</template>
<script>
  import AssetItemPreview from "../../components/AssetItemPreview"
  import {mapActions, mapState} from "vuex"
  import VideoPlayer from "../../components/VideoPlayer"
  import remoteApi from "../../services/remoteApi"

  function calculateImageWidth(imageInfo, breakpoint) {
    if (imageInfo.landscape) {
      if (breakpoint.width > imageInfo.width) {
        return imageInfo.height * imageInfo.aspectRatio
      } else {
        return imageInfo.height * imageInfo.aspectRatio
      }
    } else {
      if (breakpoint.height < imageInfo.height) {
        return imageInfo.height * imageInfo.aspectRatio
      }
      return imageInfo.height * imageInfo.aspectRatio
    }
  }

  function calculateImageHeight(imageInfo, breakpoint) {
    if (imageInfo.portrait) {
      if (breakpoint.height >= imageInfo.height) {
        return imageInfo.height
      }
      return breakpoint.height - 90
    } else {
      return imageInfo.height
    }
  }

  function calculateVideoHeight(imageInfo, breakpoint) {
    if (breakpoint.xsOnly) {
      return 225
    }
    return 338
  }

  function calculateVideoWidth(imageInfo, breakpoint) {
    if (breakpoint.xsOnly) {
      return 400
    }
    return 600
  }

  export default {
    components: {VideoPlayer, AssetItemPreview},
    props: [],
    data() {
      return {
        visible: false,
        scrollPosition: 0,
        imageVisible: false,
        videoUrl: '',
        imageInformation: {}
      }
    },
    methods: {
      ...mapActions({
        'loadAsset': 'assets/loadAsset'
      }),
      close() {
        this.visible = false
        this.$router.push({name:'Home'})
      },
      addBodyClass(className) {
        const body = document.body;
        body.classList.add(className);
      },
      removeBodyClass(className) {
        const body = document.body;
        body.classList.remove(className);
      },
      imageLoaded(imageInformation) {
        this.imageInformation = imageInformation
      }
    },
    created() {
      let assetId = this.$route.params.assetId
      this.loadAsset(assetId).then(() => {
        if (this.selectedAsset.isVideo()) {
          remoteApi.getVideoPreviewUrl(assetId).then(response => {
            this.videoUrl = response.previewUrl
            this.visible = true
          }).catch(error => {
            alert("Video currently not available " + error)
          })
        } else {
          this.visible = true
        }
      })
    },
    computed: {
      ...mapState({
        selectedAsset: state => state.assets.selectedAsset
      }),
      fullscreen() {
        if (this.selectedAsset.isVideo()) {
          return false
        }
        return false;
      },
      height() {
        if (this.selectedAsset.isVideo()) {
          return calculateVideoHeight(this.imageInformation, this.$vuetify.breakpoint)
        }
        return calculateImageHeight(this.imageInformation, this.$vuetify.breakpoint)
      },
      width() {
        if (this.selectedAsset.isVideo()) {
          return calculateVideoWidth(this.imageInformation, this.$vuetify.breakpoint)
        }
        return calculateImageWidth(this.imageInformation, this.$vuetify.breakpoint)
      },
      maxHeight() {
        if (this.selectedAsset.isVideo()) {
          return calculateVideoHeight(this.imageInformation, this.$vuetify.breakpoint)
        }
        return calculateImageHeight(this.imageInformation, this.$vuetify.breakpoint)
      },
      maxWidth() {
        if (this.selectedAsset.isVideo()) {
          return calculateVideoWidth(this.imageInformation, this.$vuetify.breakpoint)
        }
        return calculateImageWidth(this.imageInformation, this.$vuetify.breakpoint)
      }
    },
    mounted() {
      this.imageVisible = true
    },
    watch: {
      assetPreviewModal(value) {
        if (value) {
          this.scrollPosition = document.documentElement.scrollTop || document.body.scrollTop
          this.addBodyClass('no-scroll')
        } else {
          this.removeBodyClass('no-scroll')
          document.documentElement.scrollTop = document.body.scrollTop = this.scrollPosition
        }
      },
      visible(val) {
        //Close on Backdrop click/touch
        if (val === false) {
          this.visible = false
          this.close()
        }
      }
    }
  }
</script>
<style lang="css">
  .no-scroll {
    overflow-y: hidden;
    height: 100vh;
  }
</style>
